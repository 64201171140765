/* TrueNTH Nav styles
 * For use with portal_wrapper
 * Namespaced to avoid conflicts. Some styles based on Bootstrap
 */
@defaultBackgroundColor: #6f7985;
@watermarkTextColor: #603b00;
@watermarkBgColor: #eeab50;
/** Overall wrapper with defaults **/
#tnthNavWrapper {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  .eproms-substudy {
    display: none;
  }  

  a {
    text-decoration: none;
  }
  img {
    margin: 0 1em;
    vertical-align: middle;
  }
  #tnthLogo img,
  #tnthLogoXs img {
    margin: 0 auto;
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  :after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
.watermark, .watermark-nowrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  font-weight: normal;
  text-align: center;
  width: 100%;
  max-width: 100%;
  font-size: 0.58em;
  letter-spacing: 1px;
  text-transform: capitalize;
  padding: 0;
  background-color: @watermarkBgColor;
  color: @watermarkTextColor;
}
.watermark-nowrapper {
  font-size: 0.7em;
  top: 0;
  color: @watermarkTextColor;
  background-color: @watermarkBgColor;
  padding: 0.1em 0;
}
@media (min-width: 641px) {
   .watermark, .watermark-nowrapper {
      font-size: 0.7em;
    }
}
@media (min-width: 768px) {
  .watermark {
    color: #603b00;
    background-color: @watermarkBgColor;
    padding: 0;
    width: 100%;
    font-size: 0.85em;
  }
  .watermark-nowrapper {
    padding: 0;
    width: 100%;
    font-size: 0.85em;
  }
}
.nav-menu {
  display: block;
}
.no-fouc .nav-menu {
  display: none;
}
#notificationBanner {
  display: none;
}
.tnth-white {
  color: #ECF7E5;
  background-color: transparent;
  border-color: transparent;
  /*transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;*/
  text-shadow: none;
  background-image: none;
  &:hover, &:active, .tnth-open & {
    background-color: #fff;
    color: #333;
  }
  &:visited {
    color: #ECF7E5;
    background-color: transparent;
  }
}
#tnthNavMain {
  width: 100%;
  max-width: 100%;
  background-color: @defaultBackgroundColor;
  background-image: url('../img/header_background.jpg');
  height: 136px;
  background-size: cover;
  margin: 0;
  padding: 16px 24px;
}
#tnthNavMainContainer {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  #tnthLogo {
    flex: 1;
  }
  #tnthTopLinks {
    width: 35%;
  }
  .spacer {
    width: 35%;
  }
  .text-container {
    color: #FFF;
    display: none;
  }
  @media (min-width: 992px) {
    .text-container {
      display: block;
    }
  }
}
#tnthNavXs {
  display: none;
  margin: 0;
  width: 100%;
  max-width: 100%;
  background-color: @defaultBackgroundColor;
  background-image: url('../img/header_background.jpg');
  background-repeat: no-repeat;
  padding: 10px 15px;
  z-index: 1000;
  border: none;
  position: relative;
  min-height: 72px;
  overflow: hidden;
  &:after {
    clear: both;
    display: table;
    content: " ";
  }
  .tnth-navbar-toggle {
    margin: 0;
    padding: 8px 2px 8px 20px;
    position: relative;
    float: left;
    background-repeat: no-repeat;
    background-position: 4px 12px;
    background-image: url('../img/fa_bars_ffffff.png');
    background-size: auto 13px;
    border: 1px solid transparent;
    border-radius: 4px;
    img {
      margin-left: 8px;
    }
    &.tnth-navbar-no-profile {
      img {
        visibility: hidden;
        width: 1px;
        margin-left: 0;
      }
    }
    &:hover, &:active {
      cursor: pointer;
      background-image: url('../img/fa_bars_333333.png');
      background-color: #fff;
    }
  }
}
@media (min-width: 641px) {
  #tnthNavXs {
    min-height: 86px;
  }
}
@media (min-width: 699px) {
  #tnthNavXs {
    min-height: 86px;
    padding: 16px;
  }
}

/** Logo **/
#tnthLogo {
  margin: 16px auto;
  transition: margin 10ms ease-in;
  text-align: center;
}
#tnthLogoXs {
  padding: 0;
  display: block;
  max-width: 100%;
  margin: auto;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 699px) {
  #tnthLogoXs {
    top: 57.5%;
  }
}
#tnthTopLinks {
  line-height: 1.65;
  white-space: normal;
}

/** Buttons **/
.tnth-btn-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0.2em;
}
@media (min-width: 786px) {
  .tnth-btn-group {
    flex-direction: row;
  }
}
.tnth-nav-btn {
  display: inline-block;
  height: inherit;
  line-height: inherit;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
  padding: 0;
  &:hover,
  &:focus,
  &:active {
    background-color: #fff;
  }
}
#tnthUserBtn {
  padding: 0 6px;
  display: flex;
  align-items: center;
  .menu-text {
    display: inline-block;
    margin-right: 4px;
  }
  .profile-img {
    margin-left: 12px;
  }
}
@media (min-width: 992px) {
  #tnthUserBtn {
    padding: 0 16px 0 0;
    height: inherit;
  }
}
#tnthHomeBtn {
  background-image: url('../img/fa_home_ffffff.png');
  width: 38px;
  height: 52px;
  background-repeat: no-repeat;
  background-position: center;
  &:hover,
  &:focus,
  &:active {
    background-image: url('../img/fa_home_333333.png');
  }
}

/** Dropdown styles - based on Bootstrap dropdown **/
.tnth-dropdown {
  position: relative;
}
// Prevent the focus on the dropdown toggle when closing dropdowns
.tnth-dropdown-toggle:focus {
  outline: 0;
}
.tnth-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 7px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  list-style-position: inside;
  // Aligns the dropdown menu to right
  &.pull-right {
    right: 0;
    left: auto;
  }
  &.divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }
  // Links within the dropdown menu
  > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    &:hover,
    &:focus {
      text-decoration: none;
      color: #262626;
      background-color: #f5f5f5;
    }
  }
  > .active > a {
    &,
    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
      background-color: #337ab7;
      outline: 0;
    }
  }
  > .tnth-disabled > a {
    &,
    &:hover,
    &:focus {
      color: #777;
    }
    &:hover,
    &:focus {
      text-decoration: none;
      background-color: transparent;
      background-image: none; // Remove CSS gradient
      cursor: not-allowed;
    }
  }
}
// Open state for the dropdown
.tnth-open {
  // Show the menu
  > .tnth-dropdown-menu {
    display: block;
  }
  > a {
    outline: 0;
  }
}
.welcome-text {
  display: block;
  margin: 0.2em 0;
  white-space: normal;
  word-break: break-word;
  .prompt {
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .welcome-text {
    .prompt {
      display: block;
    }
  }
}
@media (min-width: 1600px) {
  .welcome-text {
    white-space: nowrap;
  }
}
@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
  /* IE10+ */
  .tnth-dropdown-menu li {
    list-style-image: url(data:0);
    list-style-position: outside;
    overflow: hidden;
  }
}
/*** Styles for responsive xs size ***/
.tnth-container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  .tnth-navbar-header {
    margin-right: -15px;
    margin-left: -15px;
    position: relative;
    &:after, &:before {
      display: table;
      content: " ";
    }
    &:after {
      clear: both;
    }
  }
}
/** Navbar for XS size **/
#tnthNavbarXs {
  padding-top: 20px;
  display: none;
}
.tnth-navbar-xs {
  margin: 57px -15px 7.5px;
  padding-left: 0;
  list-style: none;
  > li {
    position: relative;
    display: block;
  }
  > li > a {
    line-height: 20px;
    position: relative;
    display: block;
    padding: 10px 15px;
    color: #fff;
    &:hover {
      background-color: #fff;
      color: #333;
    }
  }
}
.tnth-navbarXs-img-container {
  text-align: center;
  width: 160px;
  margin: 0 auto;
}
/** Responsive sizes **/
.tnth-navbarXs-img-container img  {
  width: 25%;
  margin: 0;
}
@media (min-width: 499px) {
  .tnth-navbarXs-img-container img {
    width: 40%;
  }
}
@media (min-width: 641px) {
  .tnth-navbarXs-img-container img {
    width: 65%;
  }
}
.profile-img {
  display: none;
}
@media (min-width: 641px) {
  .profile-img {
    display: inline-block;
  }
  #tnthNavXs {
    .tnth-navbar-toggle {
      padding: 2px 2px 2px 20px;
      background-position: 4px 16px;
    }
  }
}
#tnthNavMain {
  display: none;
}
#tnthNavXs {
  display: block;
}
@media (min-width: 992px) {
  #tnthNavMain {
    display: block;
  }
  #tnthNavXs {
    display: none;
  }
}
@media(min-width: 499px) {
  #tnthLogoXs img {
    float: right;
  }
}
@media (min-width: 992px) {
  #tnthNavWrapper img {
    margin: 0 0.5em;
    vertical-align: top;
  }
}
